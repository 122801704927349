import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import RightNavBar from '../../components/docs/RightNavBar/index';
import { Box, styled } from '@mui/system';
import SEO from '../../components/common/Seo';
import theme, { BREAKPOINTS, MOBILE_SPACING } from '../../utils/theme';
import Markdoc from '@markdoc/markdoc';
import { customConfig, components } from '../../schema/config';

const useStyles = {
  outerBox: {
    display: 'flex',
    flex: '1 1 0%',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
  },
  centerBoxWithRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      width: 'calc(100% - 410px)',
      paddingLeft: theme.spacing(10),
    },
  },
  centerBoxWithNoRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '410px',
      margin: 'auto',
    },
  },
};

const ContentBox = styled('div')(({ theme }) => ({
  width: '699px',
  [BREAKPOINTS.MOBILE]: {
    width: '100%',
  },
  [BREAKPOINTS.DESKTOP]: {
    width: 'calc(100% - 410px)',
    paddingLeft: theme.spacing(10),
  },
}));

const InnerBox = styled('div')(({ theme }) => ({
  maxWidth: '989px',
  margin: '44px auto',
  marginBottom: '0px',
  [BREAKPOINTS.MOBILE]: {
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto 20px',
    marginTop: theme.spacing(3),
  },
  [BREAKPOINTS.DESKTOP]: {
    width: 'calc(100% - 88px)',
  },
}));

const SideBar = styled('div')(({ theme }) => ({
  width: '290px',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const RightBar = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  marginLeft: '40px',
  width: '290px',
  maxHeight:'calc(100% - 150px)',
  overflow:'scroll',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const buildSEO = (frontmatter, excerpt, headings) => {
  let title = '';
  let description = '';
  let docType='';
  if (frontmatter && frontmatter.title) {
    title = frontmatter.title;
  } else if (headings) {
    const headerList = headings.filter((header) => header.depth === 1);
    title = headerList && headerList[0] ? headerList[0].value : '';
  } else {
    title = '';
  }

  if (frontmatter && frontmatter.description) {
    description = frontmatter.description;
  } else {
    description = excerpt;
  }

  if(frontmatter && frontmatter.docType){
    docType=frontmatter.docType;
  }
  else{
    docType='';
  }

  return { title: title, description: description, docType: docType };
};

const putRightNavItems = (tableOfContents, rightNavItems) => {
  if (tableOfContents.items) {
    const level1 = tableOfContents.items[0];
    if (level1.items) {
      for (const level2 of level1.items) {
        rightNavItems.push({ url: level2.url, value: level2.title, depth: 2 });
        if (level2.items) {
          for (const level3 of level2.items) {
            rightNavItems.push({
              url: level3.url,
              value: level3.title,
              depth: 3,
            });
          }
        }
      }
    }
  }
};

function generateHeadings(content, headings, rightNavItems) {
  content.children.forEach(item => {
    if(item.name === 'H2' || item.name === 'H3')
    {
      rightNavItems.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name === 'H1')
    {
      headings.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name && item.name !== 'Tabs' && item.children.length > 1)
    {
      generateHeadings(item, headings, rightNavItems);
    }
  });
}


function mdoc(mdocData) {
  const {raw,fields,frontmatter} = mdocData.markdoc;
  const {excerpt} = mdocData.markdownRemark;
  const config = {
    ...customConfig,
    variables: {
      markdoc:{
        frontmatter: {
          title: frontmatter.title
        }
      },
    }
  };

  let slug='';
  if(fields && fields.slug){
   slug=fields.slug;
  }

  const rightNavItems = [] as any;
  const headings = [] as any;

  const ast = Markdoc.parse(raw);
  const content = Markdoc.transform(ast, config);
  generateHeadings(content, headings, rightNavItems);

  return (
    <>
      <SEO {...buildSEO(frontmatter, excerpt, headings)} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.innerBox}>
            <Box
              sx={
                rightNavItems.length > 0
                  ? useStyles.centerBoxWithRightNav
                  : useStyles.centerBoxWithNoRightNav
              }
            >
              <Box className="mdoc-templete">
                {Markdoc.renderers.react(content, React, {
                  components: {
                    ...components,
                  }
                })}
              </Box>
            </Box>
            {rightNavItems.length > 0 && 
              <SideBar>
                <Box>
                  <RightBar>
                    <Box>
                      <RightNavBar rightNavItems={rightNavItems} pathName={slug}/>
                    </Box>
                  </RightBar>
                </Box>
              </SideBar>
            }
          </Box>
        </InnerBox>
      </Box>
    </>
  );
}

function index(mdxData) {
  const { body, headings, frontmatter, excerpt, tableOfContents,fields} = mdxData.mdx;
  let slug='';
  if(fields && fields.slug){
   slug=fields.slug;
  }
  const rightNavItems = [] as any;
  putRightNavItems(tableOfContents, rightNavItems);
  return (
    <>
      <SEO {...buildSEO(frontmatter, excerpt, headings)} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.innerBox}>
            <Box
              sx={
                rightNavItems.length > 0
                  ? useStyles.centerBoxWithRightNav
                  : useStyles.centerBoxWithNoRightNav
              }
            >
              <Box className="mdx-templete">
                <MDXRenderer>{body}</MDXRenderer>
              </Box>
            </Box>
            {rightNavItems.length > 0 && 
              <SideBar>
                <Box>
                  <RightBar>
                    <Box>
                      <RightNavBar rightNavItems={rightNavItems} pathName={slug}/>
                    </Box>
                  </RightBar>
                </Box>
              </SideBar>
            }
          </Box>
        </InnerBox>
      </Box>
    </>
  );
}

export default ({ data }) => {
  if(data && data.mdx)
  {
    return index(data);
  }
  else if(data && data.markdoc)
  {
    return mdoc(data);
  }
  return <></>;
};

export const query = graphql`
  query PageBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      headings {
        value
        depth
      }
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
      }
      excerpt(pruneLength: 250, truncate: false)
    }
    markdoc(fields: {slug: {eq: $slug}}) {
      raw
      fields {
        slug
      }
      frontmatter {
        title
        docType
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt(pruneLength: 250, truncate: false)
    }
  }
`;
