import { Tag } from '@markdoc/markdoc';

export const tabs = {
  render: 'Tabs',
  attributes: {},
};

export const tab = {
  render: 'Tab',
  attributes: {
    label: {
      type: String
    }
  }
};

export const notice = {
  render: 'Notice',
  children: ['paragraph', 'code', 'a'],
};

export const syncTabs = {
  render: 'SyncTabs',
  attributes: {},
  children: ['tabs', 'tab', 'heading', 'paragrapgh', 'item', 'li', 'pre', 'inline'],
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    return new Tag('SyncTabs', attributes, children);
  }
};

export const tag = {
  render: 'Tag',
  attributes: {
    title: {type: String},
    tagsList: {type: Array}
  },
};

export const br = {
  render: 'Br',
};

export const li = {
  render: 'ListItem',
  children: [
    'inline',
    'heading',
    'paragraph',
    'image',
    'table',
    'tag',
    'fence',
    'blockquote',
    'list',
    'hr',
  ],
};

export const ul = {
  children: ['li'],
  attributes: {},
  transform(node, config) {
    return new Tag(
      'Ul',
      node.transformAttributes(config),
      node.transformChildren(config)
    );
  },
};

export const img = {
  render: 'Image',
  attributes: {
    src: { type: String, required: true },
    alt: { type: String },
    title: { type: String },
    height: { type: String },
    width: { type: String },
    // width/height attributes will need to be to be implemented as an extension to markdown-it
  },
}; 

export const div = {
  render: 'Div',
  children: ['inline'],
  attributes: {
    align: { type: String}
  }
};
