import { Tag } from '@markdoc/markdoc';

// Or replace this with your own function
function generateID(children, attributes) {
  if (attributes.id && typeof attributes.id === 'string') {
    return attributes.id;
  }

  return children
    .filter((child) => typeof child === 'string')
    .join(' ')
    .replace(/[?]/g, '')
    .replace(/\s+/g, '-')
    .toLowerCase();
}

export const heading = {
  children: ['inline'],
  attributes: {
    id: { type: String },
    level: { type: Number, required: true, default: 1 }
  },
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);
    
    const id = generateID(children, attributes);
    
    return new Tag(
      `H${node.attributes['level']}`,
      { ...attributes, id },
      children
    );
  }
};

export const paragraph = {
  render: 'Paragraph',
  children: ['inline'],
  attributes: {}
};

export const table = {
  render: 'Table',
  children: [],
  attributes: {}
};

export const tr = {
  render: 'Tr',
  children: ['th', 'td'],
};

export const th = {
  render: 'Th',
  attributes: {
      width: { type: Number },
      align: { type: String },
  },
};

export const td = {
  render: 'Td',
  children: [
    'inline',
    'heading',
    'paragraph',
    'image',
    'table',
    'tag',
    'fence',
    'blockquote',
    'list',
    'hr',
  ],
  attributes: {
    colspan: { type: Number },
    rowspan: { type: Number },
    align: { type: String },
  },
};

export const image = {
  render: 'Image',
  attributes: {
    src: { type: String, required: true },
    alt: { type: String },
    title: { type: String },
    height: { type: String },
    width: { type: String },
    // width/height attributes will need to be to be implemented as an extension to markdown-it
  },
}; 

export const fence = {
  render: 'Pre',
  attributes: {
    content: { type: String, render: false, required: true },
    language: { type: String, render: 'className' },
    process: { type: Boolean, render: false, default: true },
  },
  transform(node, config) {
    const attributes = node.transformAttributes(config);
    const children = node.children.length
      ? node.transformChildren(config)
      : [node.attributes.content];

    return new Tag('Pre', attributes, children);
  },
};

export const item = {
  render: 'Li',
  children: [
    'inline',
    'heading',
    'paragraph',
    'image',
    'table',
    'tag',
    'fence',
    'blockquote',
    'list',
    'hr',
  ],
};
  
export const list = {
  children: ['item'],
  attributes: {
    ordered: { type: Boolean, render: false, required: true },
  },
  transform(node, config) {
    return new Tag(
      node.attributes.ordered ? 'Ol' : 'Ul',
      node.transformAttributes(config),
      node.transformChildren(config)
    );
  },
};

export const link = {
  render: 'A',
  children: ['strong', 'em', 's', 'code', 'text', 'tag'],
  attributes: {
    href: { type: String, required: true },
    title: { type: String },
  },
};
