import * as React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import LinkWrapper from '../../common/Link';
import Typography from '../../common/Typography';
import globalMessages from '../../../utils/messages/en/globalMessages';
import { useFlags } from 'gatsby-plugin-launchdarkly';

const useStyles = {
  icon: {
    color: COLORS.TEXT_LOW_EMPHASIS,
    height: '14px',
    width: '14px',
  },
  active_text: {
    color: COLORS.TEXT_HIGH_EMPHASIS,
    ':hover': {
      color: COLORS.SECONDARY_DUSK,
    },
  },
  default: {
    ':hover': {
      color: COLORS.SECONDARY_DUSK,
    },
    color: COLORS.TEXT_LOW_EMPHASIS,
  },
  selector_active: {
    ml: '14px',
    color: COLORS.SECONDARY_NIGHT,
    mt: '4px',
  },
  selector_default: {
    ml: '14px',
    mt: '4px',
  },
  parent_selector_active: {
    color: COLORS.SECONDARY_NIGHT,
    padding: '0px 18px 10px 0px',
  },
  parent_selector_default: {
    padding: '0px 18px 10px 0px',
  },
  title_box: {
    color: COLORS.TEXT_LOW_EMPHASIS,
  },
  list_style: {
    minWidth: '250px',
    maxWidth: '250px',
    paddingTop: '12px',
    paddingBottom: '0px',
  },
  listItemButton: {
    padding: '0px 0px',
  },
  listItemText: {
    minWidth: '250px',
    marginTop: '0px',
    marginBottom: '0px',
  },
};

const selector = (headerDepth, activeItem, currentItem) => {
  if (headerDepth === 3) {
    return activeItem === currentItem
      ? useStyles.selector_active
      : useStyles.selector_default;
  } else {
    return activeItem === currentItem
      ? useStyles.parent_selector_active
      : useStyles.parent_selector_default;
  }
};
const isNotNull = (item, ExpectedDepth) => {
  if (item && item.value && item.depth === ExpectedDepth) {
    return true;
  } else {
    return false;
  }
};
const textSelector = (activeItem, currentItem) => {
  return activeItem === currentItem ? useStyles.active_text : useStyles.default;
};

const escapeTagsFromString = (header) => {
  let i = 0;
  let flag = false;
  for (; i < header.length; i++) {
    if (header[i] === '<') {
      flag = true;
      break;
    }
  }
  if (flag === true) {
    return header.slice(0, i - 1);
  } else {
    return header;
  }
};
const getH3Tags = (rightNavItems, parentIndex, activeItem, handleClick) => {
  const childs = [] as any;
  const startIndex = parentIndex + 1;
  const depth = 3;
  for (let i = startIndex; i < rightNavItems.length; i++) {
    if (rightNavItems[i].depth === 3) {
      childs.push(rightNavItems[i].value);
    } else {
      break;
    }
  }
  return (
    <div>
      {childs.map((Item, index) => 
        <div key={startIndex + index}>
          {startIndex + index < rightNavItems.length &&
            isNotNull(rightNavItems[startIndex + index], 3) && 
              <List
                component="div"
                key={index}
                disablePadding
                sx={selector(
                  depth,
                  activeItem,
                  rightNavItems[startIndex + index].url,
                )}
              >
                <LinkWrapper href={`${rightNavItems[startIndex + index].url}`}>
                  <ListItemButton
                    onClick={() =>
                      handleClick(rightNavItems[startIndex + index].url)
                    }
                    style={useStyles.listItemButton}
                  >
                    <ListItemText sx={useStyles.listItemText}>
                      <Typography
                        type={
                          activeItem === rightNavItems[startIndex + index].url
                            ? 'DESKTOP_BODY_PARAGRAPH_MEDIUM'
                            : 'DESKTOP_BODY_PARAGRAPH'
                        }
                        sx={textSelector(
                          activeItem,
                          rightNavItems[startIndex + index].url,
                        )}
                      >
                        {rightNavItems[startIndex + index].value}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </LinkWrapper>
              </List>
            }
        </div>
      )}
    </div>
  );
};
const addRightNavClickEventToDataLayer = (route, pathName) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'rightNavClickSubmission',
      rightNavLinkId: route + '(' + pathName + ')',
    });
  }
  return '';
};
const RightNavBar: React.FC<any> = ({ rightNavItems, pathName }) => {
  const [activeItem, setActiveItem] = React.useState<any>('');
  const flags = useFlags();
  for (const element of rightNavItems) {
    element.value = escapeTagsFromString(element.value);
  }
  const onScroll = () => {
    let current = '';
    const sections: NodeListOf<HTMLElement> = document.querySelectorAll(
      '.header-tag',
    );
    let lastSection = '';
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      const temp = section.getAttribute('id');
      if (scrollY >= sectionTop - sectionHeight - 60) {
        current = temp !== null ? temp : '';
      }
      lastSection = temp !== null ? temp : '';
    });
    const scrollable =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = Math.round(window.scrollY);
    if (scrolled === scrollable) {
      return lastSection;
    } else {
      return current;
    }
  };
  React.useEffect(() => {
    let isMounted = true;
    if (typeof window !== 'undefined' && isMounted) {
      window.addEventListener('scroll', () => {
        const temp = onScroll();
        if (temp !== '') {
          setActiveItem('#' + temp);
        }
      });
    }
    return () => {
      isMounted = false;
      setActiveItem({});
    };
  }, []);
  const appendRouteToUrl = (routeToBeAppended) => {
    const selectionItem = routeToBeAppended;
    if (selectionItem && selectionItem.includes('#')) {
      const search = '#';
      const replaceWith = '';
      const result = selectionItem.split(search).join(replaceWith);
      const ele = document.getElementById(result);
      if (ele) {
        ele.scrollIntoView();
        window.history.pushState(
          null,
          '',
          window.location.href.split('#')[0] + selectionItem,
        );
      }
    }
  };
  const handleClick = (currentItem) => {
    if (flags.enableDocAnalyticsEvents === true) {
      addRightNavClickEventToDataLayer(
        currentItem.startsWith('#')
          ? currentItem.substring(1, currentItem.length)
          : currentItem,
        pathName,
      );
    }
    appendRouteToUrl(currentItem);
  };

  return (
    <div>
      {rightNavItems.length >= 1 && 
        <Typography type="DESKTOP_BODY_PARAGRAPH" sx={useStyles.title_box}>
          {globalMessages.rightNavHeader}
        </Typography>
      }
      <List
        sx={useStyles.list_style}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {rightNavItems.map((currentItem, index) => 
          <div key={index}>
            {isNotNull(currentItem, 2) && 
              <>
                <List
                  component="div"
                  disablePadding
                  sx={selector(currentItem.depth, activeItem, currentItem.url)}
                >
                  <LinkWrapper href={`${currentItem.url}`}>
                    <ListItemButton
                      onClick={() => handleClick(currentItem.url)}
                      style={useStyles.listItemButton}
                    >
                      <ListItemText sx={useStyles.listItemText}>
                        <Typography
                          type={
                            activeItem === currentItem.url
                              ? 'DESKTOP_BODY_PARAGRAPH_MEDIUM'
                              : 'DESKTOP_BODY_PARAGRAPH'
                          }
                          sx={textSelector(activeItem, currentItem.url)}
                        >
                          {currentItem.value}
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </LinkWrapper>
                  {getH3Tags(rightNavItems, index, activeItem, handleClick)}
                </List>
              </>
            }
          </div>
        )}
      </List>
    </div>
  );
};
export default RightNavBar;
