import { getHeadingTag, getListTag, getOLTags, getParagraph, getSectionHeadingTag, getULTag, getAnchorTag, hardbreak, listItem, getDivTag} from '../components/docs/MDXComponents';
import Table from '../components/common/Table';
import TableHeader from '../components/common/TableHeader';
import TableRow from '../components/common/TableRow';
import TableData from '../components/common/TableData';
import {heading, paragraph, table, tr, th, td, image, fence, list, item, link} from '../schema/nodes.markdoc';
import {tabs, tab, notice, syncTabs, tag, br, ul, li, img, div} from './tags.markdoc';
import Image from '../components/common/Image';
import RequestResponse from '../components/api-ref/RequestResponse';
import Tabs, { Tab } from '../components/docs/Tabs';
import Notice from '../components/docs/Notice';
import SyncTabs from '../components/docs/SyncTabs';
import Tag from '../components/docs/Tag';

export const customConfig = {
    tags: {
        tabs,
        tab,
        notice,
        syncTabs,
        tag,
        br,
        ul,
        li,
        img,
        div,
    },
    nodes: {
        heading,
        paragraph,
        table,
        tr,
        th,
        td,
        image,
        fence,
        list,
        item,
        link,
    },
};

export const components = {
    Paragraph: (props: any) => getParagraph(props),
    Table: (props) => Table(props),
    Tr: (props) => TableRow(props),
    Th: (props) => TableHeader(props),
    Td: (props) => TableData(props),
    Image: Image,
    H1: (props) => getHeadingTag('h1', props),
    H2: (props) => getSectionHeadingTag('h2', props),
    H3: (props) => getSectionHeadingTag('h3', props),
    H4: (props) => getHeadingTag('h4', props),
    H5: (props) => getHeadingTag('h5', props),
    H6: (props) => getHeadingTag('h6', props),
    Pre: RequestResponse,
    Li: (props) => getListTag(props),
    A: (props) => getAnchorTag(props),
    Ul: (props) => getULTag(props),
    Ol: (props) => getOLTags(props),
    Tabs: Tabs,
    Tab: Tab,
    Notice: ({children}) => Notice(children.props),
    SyncTabs: SyncTabs,
    Tag: Tag,
    Br: hardbreak,
    ListItem: (props) => listItem(props), 
    Div: (props) => getDivTag(props),
};